import { createReducer,on } from '@ngrx/store';
import { UserItem } from '../models/user-item';
import { setUser } from '../actions/user.action';

//create a dummy initial state
export const initialState: UserItem = 
{
    user: null,
};

export const userReducer = createReducer(
    initialState,
    on(setUser, (state,{user}) => {
        let obj = Object.assign({},state);
        obj.user = user;
        return obj;
    }),
);