import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { AlertService } from 'services/alert-service/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { LogOutErrorDialogComponent } from '@app/modules/error/logout-error-dialog.component';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private static islogOutShowed = false;
  constructor(private alertService: AlertService, private dialog: MatDialog) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error) {
          switch (error.status) {
            case 401:
              this.error401(error);
              break;
            case 403:
              this.showAlertUnauthorized();
              break;
            case 500:
              this.error500(error);
              break;
            default:
              this.defaultError();
              break;
          }
        }

        return throwError(() => error);
      })
    );
  }

  private error401(error: any) {
    if (error.error == 'Bearer token missing (1btmmtb1)') {
      console.log(error.error);
      this.showDialog();
    } else {
      this.showAlertUnauthorized();
    }
  }

  private error500(error: any) {
    if (error.error?.Message) {
      this.alertService.error(error.error?.Message);
    }
  }

  private defaultError() {
    if (!HttpErrorInterceptor.islogOutShowed) {
      this.showAlertUnexcepdet();
    }
  }

  private showDialog() {
    if (HttpErrorInterceptor.islogOutShowed) return;

    HttpErrorInterceptor.islogOutShowed = true;
    this.dialog.open(LogOutErrorDialogComponent);
  }

  private showAlertUnauthorized() {
    this.alertService.error(
      'Unauthorized access - Sorry, your request could not be processed!'
    );
  }

  private showAlertUnexcepdet() {
    this.alertService.error(
      'An unexpected error occurred, please contact support if the issue persists.'
    );
  }
}
