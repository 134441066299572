import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IdleService } from '@app/shared/services/idle.service';
import { WindowService } from '@app/shared/services/window.service';
import { AuthService } from 'services/auth-service/auth.service';

@Component({
  selector: 'logout-Error-dialog',
  templateUrl: './logout-error-dialog.component.html',
  styleUrls: ['./logout-error-dialog.component.scss'],
  standalone: false,
})
export class LogOutErrorDialogComponent implements OnInit {
  private locationFromService: Location;
  constructor(
    private dialogRef: MatDialogRef<LogOutErrorDialogComponent>,
    private authService: AuthService,
    private idleService: IdleService,
    private windowService: WindowService
  ) {
    dialogRef.disableClose = true;
    this.locationFromService = windowService.getLocation();
  }

  ngOnInit() {
    this.idleService.stopWatching();

    this.authService.removeData();
  }

  protected redirect() {
    this.locationFromService.href = '..';
  }
}
