import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { LogOutErrorDialogComponent } from './logout-error-dialog.component';

@NgModule({
  imports: [SharedModule],
  declarations: [LogOutErrorDialogComponent],
  exports: [LogOutErrorDialogComponent],
})
export class ErrorModule {}
