<div class="timeout-dialog dialog-container">
  <mat-icon class="icon text-accent">info_outlined</mat-icon>
  <h1>Your session is expire, please login again.</h1>
  <div class="buttons">
    <app-button
      text="Login Page"
      color="accent-empty"
      (btnClick)="redirect()"
    ></app-button>
  </div>
</div>
